//Как потерять друзей

export const url_serv_lose_friends = "https://lose-friends-b2c531fd41a8.herokuapp.com";
export const url_serv_lose_friends_wss = "wss://lose-friends-b2c531fd41a8.herokuapp.com";




// export const url_serv_lose_friends = "http://localhost:8003";   
// export const url_serv_lose_friends_wss = "ws://localhost:8003";





//Морской бой

export const url_serv_battle_sea = "https://seabattle-acb2eb1faa50.herokuapp.com";
export const url_serv_battle_sea_wss = "wss://seabattle-acb2eb1faa50.herokuapp.com/ws/"

// export const url_main_page = 'https://fizi.cc';

export const url_main_page = 'https://fizi.gg';


// export const url_serv_battle_sea = "http://localhost:7003";  
// export const url_serv_battle_sea_wss = "ws://localhost:7003/ws/";

// export const url_main_page = 'http://localhost:8080';


//Статистика
// export const url_stat = 'http://localhost:9999/create-room';
export const url_stat = 'https://fizistat-33157f0b8398.herokuapp.com/create-room';





export const url_serv_checkers = 'http://localhost:8111';

export const url_serv_checkers_wss = 'ws://localhost:8111';




export const url_serv_uno = 'http://localhost:7888/uno';

export const url_serv_uno_wss = 'ws://localhost:7888/uno';



// export const url_serv_fool = 'http://localhost:7888/fool';

// export const url_serv_fool_wss = 'ws://localhost:7888/fool';



export const url_serv_fool = 'https://fizi-card-2b46e64751de.herokuapp.com/fool';

export const url_serv_fool_wss = 'wss://fizi-card-2b46e64751de.herokuapp.com/fool';
