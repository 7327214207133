<template>
  <GameLayout :name-game="$t('games.fool.name')">

    <div class="containerFormCreate">
      <form @submit.prevent="createRoom" class="formCreate">
        <div class="formElement">
          <label for="playerName">{{ $t('games.fool.playerName') }}:</label>
          <input v-model="playerName" type="text" id="playerName" required class="input-gradient" />
        </div>
        <div class="formElement">
          <label for="numPlayers">{{ $t('games.fool.numPlayers') }}:</label>
          <input v-model.number="numPlayers" type="number" id="numPlayers" min="2" max="6" required class="input-gradient" />
        </div>
        <button type="submit" :disabled="!isFormValid" class="btn-grad">{{ $t('games.fool.createRoom') }}</button>
      </form>
      <div v-if="errorMessage" class="error-message">
        {{ $t('games.fool.errorMessage') }}
      </div>
    </div>
  </GameLayout>
</template>
  




  <script setup>
  import { ref, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { url_serv_fool } from "@/link";
  import GameLayout from '../GameLayout.vue';


  const playerName = ref(localStorage.getItem('playerName') || '');
  const numPlayers = ref(2);
  const errorMessage = ref('');
  const router = useRouter();
  
  const isFormValid = computed(() => {
    return playerName.value.trim().length > 0 && numPlayers.value >= 2 && numPlayers.value <= 6;
  });
  
  const createRoom = async () => {
    if (!isFormValid.value) {
      errorMessage.value = 'Пожалуйста, введите корректные данные.';
      return;
    }
  
    try {
      const response = await axios.post(`${url_serv_fool}/api/create-room`, {
        player_name: playerName.value,
        num_players: numPlayers.value
      });
  
      const { room_id: roomId, player_id: playerId } = response.data;
  
      localStorage.setItem('fool_room_id', roomId);
      localStorage.setItem('fool_player_id', playerId);
      localStorage.setItem('fool_playerName', playerName.value);
      localStorage.setItem('fool_num_players', numPlayers.value);
  
      router.push({ name: 'FoolGameRoom', params: { roomId, playerId } });
    } catch (error) {
      console.error('Ошибка при создании комнаты:', error);
      errorMessage.value = 'Произошла ошибка при создании комнаты. Пожалуйста, попробуйте снова.';
    }
  };
  </script>
  
  <style scoped>

  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  </style>
  